import { Fragment, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Upload } from "@mui/icons-material";
import { MyFile } from "./Files";
import { useToastsDispatch } from "./Toasts/ToastsContext";

export default function FileDrop({ value = [], onChange }) {
  const dispatchToast = useToastsDispatch();
  const { t } = useTranslation(["common", "vault"]);

  const onDrop = (acceptedFiles, fileRejections) => {
    // onChange((prevFiles) => [...prevFiles, ...acceptedFiles]);
    onChange(acceptedFiles); // using only one file

    if (fileRejections.length > 0) {
      for (const fileRejection of fileRejections) {
        const errors = fileRejection.errors;
        for (const error of errors) {
          dispatchToast({
            type: "add",
            variant: "warning",
            heading: t(`vault:common.toasts.${error.code}.heading`),
            subheading: t(`vault:common.toasts.${error.code}.subheading`),
          });
        }
      }
    }
  };

  const multipleExtensionValidator = (file) => {
    if (typeof file === File && file.name.match(/\./g).length !== 1) {
      return "wrongExtensionWarning";
    }
    return null;
  };

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: { "application/pdf": [], "image/jpeg": [], "image/png": [] },
    maxFiles: 1,
    maxSize: 20971520, // 20 MB
    validator: multipleExtensionValidator,
    onDrop,
  });

  useEffect(() => {}, [fileRejections]);

  const handleDeleteFile = () => {
    // onChange((prevFiles) =>
    //   prevFiles.filter((prevFile) => prevFile.path !== path),
    // );
    onChange([]); // using only one file
  };

  return (
    <div className="FileDrop" onClick={(e) => e.stopPropagation()}>
      <div {...getRootProps({ className: "FileDrop-DropZone" })}>
        <label htmlFor="fileInput">
          {value.length === 0 ? (
            <div className="FileDrop-DropZone-banner">
              <div className="FileDrop-DropZone-banner-icon">
                <Upload />
              </div>
              <div className="FileDrop-DropZone-banner-text">
                {t("vault:common.fileDrop.banner")}
              </div>
              <div className="FileDrop-DropZone-banner-subtext">
                {t("vault:common.fileDrop.fileSize")}
              </div>
            </div>
          ) : null}
        </label>
        <input {...getInputProps()} name="fileInput" id="fileInput" />
      </div>
      {value.length > 0 ? (
        <div className="FileDrop-FileList">
          {value.map((file) => (
            <Fragment key={file.path}>
              <MyFile
                card
                name={file.path}
                onDelete={() => handleDeleteFile(file.path)}
              />
            </Fragment>
          ))}
        </div>
      ) : null}
    </div>
  );
}
