import { useState } from "react";
import Form from "../../commons/Form";
import { Setting, SettingDisplay, SettingModder } from "../Settings";
import api from "../../../api/resources";
import { useTranslation } from "react-i18next";
import { useModalDispatch } from "../../commons/Modal/ModalContext";

export default function PhoneSet({ value, onSuccess }) {
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.phone.label");

  const modal = {
    component: <PhoneForm label={label} initValue={value} />,
    onSuccess: onSuccess,
  };

  return (
    <Setting>
      <SettingDisplay label={label} value={value} />
      <SettingModder modal={modal} />
    </Setting>
  );
}

function PhoneForm({ label, initValue }) {
  const dispatchModal = useModalDispatch();
  const [value, setValue] = useState(initValue.replaceAll(" ", ""));
  const [errorsObj, setErrorsObj] = useState({});

  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  const handleSubmit = async () => {
    setErrorsObj({});
    const response = await api.updateUser(JSON.stringify({ telephone: value }));
    if (response.ok) {
      dispatchModal({ type: "success" });
    } else {
      const errors = await response.json();
      setErrorsObj(errors);
    }
  };

  const inputs = [
    {
      label: label,
      name: "phone",
      type: "tel",
      value: value && value.replaceAll(" ", ""),
      onChange: setValue,
      errors: getErrors("phone"),
      required: true,
    },
  ];

  const form = {
    inputs: [
      {
        data: inputs,
      },
    ],
    inputErrors: Object.keys(errorsObj) ? true : false,
    onSubmit: handleSubmit,
    errors: getErrors("non_field_errors"),
  };

  return <Form {...form} />;
}
