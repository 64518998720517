import { useCallback, useEffect, useState } from "react";
import { Content, Header, Main } from "../commons/Template";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/resources";
import Form, { FormLoader } from "../commons/Form";
import routes from "../../routes/routes";
import { Drawer } from "@mui/material";
import Button from "../commons/Button";
import Card, { CardText } from "../commons/Card";
import ImageContainer from "../commons/ImageContainer";

import {
  ArrowForward,
  ChevronRight,
  Close,
  ExpandMore,
  LocalShipping,
  Receipt,
  Savings,
  Sell,
  SwapHoriz,
} from "@mui/icons-material";
import airpme from "../../assets/airpme.png";
import aria from "../../assets/aria.png";
import avanseo from "../../assets/avanseo.png";
import capec from "../../assets/capec.jpg";
import defacto from "../../assets/defacto.png";
import dimpl from "../../assets/dimpl.png";
import edebex from "../../assets/edebex.png";
import karmen from "../../assets/karmen.png";
import memobank from "../../assets/memobank.png";
import ocean from "../../assets/ocean.png";
import riverbank from "../../assets/riverbank.png";
import silvr from "../../assets/silvr.png";
import youtrade from "../../assets/youtrade.png";
import { i18n } from "../../i18n";
import logo from "../../assets/logo-c.png";
import { useModalDispatch } from "../commons/Modal/ModalContext";
import { capitalize } from "../../utils";
import useFormatValue from "./useFormatValue";
import clients from "../../assets/clients.jpg";
import long_term from "../../assets/long_term.jpg";
import short_term from "../../assets/short_term.jpg";
import stock from "../../assets/stock.jpg";
import vendors from "../../assets/vendors.jpg";

export default function Assessment({ reseller }) {
  // flow
  const goForward = (need) => {
    const target = routes.Financing.unlock;
    const state = {
      need: need,
    };
    navigate(target, { state: state });
  };
  // end flow

  const { t } = useTranslation(["common", "financing"]);
  document.title = t("financing:assessment.title");
  const navigate = useNavigate();
  const dispatchModal = useModalDispatch();

  const { clientID } = useParams();
  const [companyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState(true);
  const yesNoOptions = [
    {
      label: t("common:yes"),
      value: true,
    },
    {
      label: t("common:no"),
      value: false,
    },
  ];
  const [questions, setQuestions] = useState([
    {
      id: "is_ecommerce",
      type: "radio",
      options: yesNoOptions,
    },
    {
      id: "is_commercial",
      value: false,
      type: "radio",
      options: yesNoOptions,
    },
    {
      id: "is_organisation",
      value: false,
      type: "radio",
      options: yesNoOptions,
    },
    {
      id: "turnover",
      value: "",
      type: "number",
      min: 0,
    },
    {
      id: "equity",
      value: "",
      type: "number",
    },
    {
      id: "gross_operating_profit",
      value: "",
      type: "number",
    },
    {
      id: "net_income",
      value: "",
      type: "number",
    },
  ]);

  const { results, setResults } = useResults(null);

  const dispatchWelcomeModal = () => {
    const note = (
      <Trans
        i18nKey="financing:assessment.modal.note"
        components={{
          emphasis: <strong />,
        }}
      />
    );

    dispatchModal({
      type: "add",
      title: t("financing:assessment.modal.title"),
      message: t("financing:assessment.modal.message"),
      size: "medium",
      note: note,
      component: <WelcomeModal />,
    });
  };

  const initQuestions = (company) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) => ({
        ...question,
        value: !company[question.id]
          ? typeof company[question.id] === "boolean"
            ? false
            : ""
          : company[question.id],
        currency: company.currency,
      })),
    );
  };

  const isFirstAssessment = (company) =>
    !typeof company.is_ecommerce === "boolean";

  const init = useCallback(async () => {
    if (!reseller) {
      const response = await api.getActiveCompany();
      if (response.ok) {
        const activeCompany = await response.json();
        setCompanyName(activeCompany.name);
        if (isFirstAssessment(activeCompany)) {
          dispatchWelcomeModal();
        } else {
          const assessResponse = await api.assessActiveCompany();
          if (assessResponse.ok) {
            const assessJsonResponse = await assessResponse.json();
            setResults(assessJsonResponse);
          }
        }
        initQuestions(activeCompany);
        setLoading(false);
      }
    } else {
      const response = await api.getClients();
      if (response.ok) {
        const clients = await response.json();
        const currentClient = clients.filter(
          (client) => client.id.toString() === clientID,
        )[0];

        if (!currentClient) {
          navigate(routes.Reseller.clients);
        } else {
          setCompanyName(currentClient.name);
          if (isFirstAssessment(currentClient)) {
            dispatchWelcomeModal();
          } else {
            const assessResponse = await api.assessClient(clientID);
            if (assessResponse.ok) {
              const assessJsonResponse = await assessResponse.json();
              setResults(assessJsonResponse);
            }
          }
          initQuestions(currentClient);
          setLoading(false);
        }
      }
    }
  }, [clientID, navigate]);

  useEffect(() => {
    init();
  }, [init]);

  const handleQuestionChange = (value, questionID) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((prevQuestion) =>
        prevQuestion.id === questionID
          ? {
              ...prevQuestion,
              value: value,
            }
          : prevQuestion,
      ),
    );
  };

  const handleSubmit = async () => {
    const entries = questions.map((question) => [question.id, question.value]);
    const updatedCompany = Object.fromEntries(entries);
    const response = reseller
      ? await api.assessClient(clientID, JSON.stringify(updatedCompany))
      : await api.assessActiveCompany(JSON.stringify(updatedCompany));
    if (response.ok) {
      const jsonResponse = await response.json();
      setResults(jsonResponse);
      init();
    }
  };

  const heading = reseller
    ? `${t("financing:assessment.title")} ${t("common:for")} ${companyName}`
    : t("financing:assessment.title");

  return (
    <Main themeSwitch>
      <Header
        heading={heading}
        subheading={t("financing:assessment.subheading")}
      />
      <Content>
        {loading ? (
          <FormLoader />
        ) : !results ? (
          <Questions
            questions={questions}
            onChange={handleQuestionChange}
            onSubmit={handleSubmit}
            onBack={() => init()}
          />
        ) : (
          <Results
            results={results}
            onClear={() => setResults(null)}
            reseller={reseller}
            onNext={goForward}
          />
        )}
      </Content>
    </Main>
  );
}

function WelcomeModal() {
  const dispatchModal = useModalDispatch();
  const { t } = useTranslation(["common", "financing"]);
  return (
    <Form
      button={t("financing:assessment.modal.button")}
      onSubmit={() => dispatchModal({ type: "success" })}
    />
  );
}

function Questions({ questions, onChange, onSubmit, onBack }) {
  const { t } = useTranslation(["common", "financing"]);
  const [index, setIndex] = useState(0);

  const goBack = () => {
    index > 0
      ? setIndex((prevIndex) => (prevIndex >= 1 ? prevIndex - 1 : prevIndex))
      : onBack();
  };

  const goForward = () => {
    setIndex((prevIndex) =>
      prevIndex < questions.length - 1 ? prevIndex + 1 : prevIndex,
    );
  };

  const handleForward = () =>
    index < questions.length - 1 ? goForward() : onSubmit();

  return (
    <div className="Questions">
      <h4>{t("financing:assessment.questions.title")}</h4>
      <ProgressBar length={questions.length} index={index} />
      <Question
        {...questions[index]}
        question={t(
          ["financing:assessment.questions.keys", questions[index].id].join(
            ".",
          ),
        )}
        onChange={(value) => onChange(value, questions[index].id)}
        onForward={handleForward}
        onBackward={goBack}
        button={
          index < questions.length - 1
            ? t("financing:assessment.questions.buttons.continue")
            : t("financing:assessment.questions.buttons.end")
        }
        onSubmit={onSubmit}
      />
    </div>
  );
}

function Results({
  reseller,
  results = {
    financialNeed1: {
      partner1: true,
      partner2: false,
    },
    financialNeed2: {
      partner1: true,
      partner2: false,
      partner3: true,
    },
  },
  onClear,
  onNext,
}) {
  const { t } = useTranslation(["common", "financing"]);
  const [selectedResult, setSelectedResult] = useState(null);
  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = () => setDrawer((drawer) => !drawer);

  const handleSelectResult = (id, eligible) => {
    if (eligible && !reseller) {
      onNext(id);
    } else {
      setSelectedResult(id);
      toggleDrawer();
    }
  };

  useEffect(() => {
    if (!drawer) {
      setSelectedResult("");
    }
  }, [drawer]);

  const resultIcons = [
    {
      id: "clients",
      icon: <Receipt />,
    },
    {
      id: "long_term",
      icon: <Savings />,
    },
    {
      id: "short_term",
      icon: <SwapHoriz />,
    },
    {
      id: "stock",
      icon: <LocalShipping />,
    },
    {
      id: "vendors",
      icon: <Sell />,
    },
  ];

  const resultImages = [
    {
      id: "clients",
      img: clients,
    },
    {
      id: "long_term",
      img: long_term,
    },
    {
      id: "short_term",
      img: short_term,
    },
    {
      id: "stock",
      img: stock,
    },
    {
      id: "vendors",
      img: vendors,
    },
  ];

  const navigate = useNavigate();

  return (
    <div className="Results">
      <div className="Results-container">
        {Object.entries(results)
          .sort(([result]) =>
            Object.values(result).some((value) => value) ? -1 : 1,
          ) // sort by eligible first
          .map(([id, result]) => (
            <Result
              key={id}
              id={id}
              name={t(`financing:assessment.results.keys.${id}.name`)}
              description={t(
                `financing:assessment.results.keys.${id}.description`,
              )}
              img={resultImages.find((result) => result.id === id).img}
              icon={resultIcons.filter((result) => result.id === id)[0].icon}
              result={result}
              selected={selectedResult === id}
              onSelect={handleSelectResult}
              eligible={Object.values(result).some((value) => value)}
            />
          ))}
      </div>

      <div className="Results-after">
        <div className="Results-after-text">
          <p className="Results-after-text-title">
            {t("financing:assessment.results.restart.title")}
          </p>
          <p className="Results-after-text-subtitle">
            {t("financing:assessment.results.restart.subtitle")}
          </p>
        </div>

        <div className="Results-after-buttons">
          <Button
            text={t("financing:assessment.results.restart.buttons.restart")}
            variant="secondary_b"
            onClick={onClear}
          />
          {reseller && (
            <Button
              text={t(
                "financing:assessment.results.restart.buttons.backToClients",
              )}
              variant="primary_a"
              onClick={() => navigate(routes.Reseller.clients)}
            />
          )}
        </div>
      </div>
      <Partners
        resultType={selectedResult}
        result={selectedResult && Object.entries(results[selectedResult])}
        open={drawer}
        onClose={toggleDrawer}
        eligible={
          selectedResult &&
          Object.values(results[selectedResult]).some((value) => value)
        }
        onNext={reseller ? null : () => onNext(selectedResult)}
      />
    </div>
  );
}

function Partners({
  result = [
    ["partnerA", true],
    ["partnerB", false],
    ["partnerC", true],
  ],
  open,
  onClose,
  onNext,
  eligible,
}) {
  const { t } = useTranslation(["common", "financing"]);
  const [selectedPartner, setSelectedPartner] = useState("");

  // expand first partner in the list on init
  const openFirstPartner = false;
  useEffect(() => {
    if (openFirstPartner) {
      if (result) setSelectedPartner(result[0][0]);
    }
  }, [result]);

  const handleSelectPartner = (newPartner) => {
    setSelectedPartner((prevPartner) =>
      prevPartner === newPartner ? "" : newPartner,
    );
  };

  const partnerTransKey = (partner) =>
    `financing:assessment.partners.${partner}`;

  const getSection = (partner, section) => {
    return {
      id: section,
      title: t([partnerTransKey(partner), section, "title"].join(".")),
      items: t([partnerTransKey(partner), section, "items"].join("."), {
        returnObjects: true,
      }),
    };
  };

  const partnersData = [
    {
      id: "AirPMEClients",
      name: "AirPME",
      logo: airpme,
    },
    {
      id: "AirPMEVendors",
      name: "AirPME",
      logo: airpme,
    },
    {
      id: "Aria",
      name: "Aria",
      logo: aria,
    },
    {
      id: "Avanseo",
      name: "Avanseo",
      logo: avanseo,
    },
    {
      id: "CapEc",
      name: "CapEc",
      logo: capec,
    },
    {
      id: "Defacto",
      name: "Defacto",
      logo: defacto,
    },
    {
      id: "Dimpl",
      name: "Dimpl",
      logo: dimpl,
    },
    {
      id: "Edebex",
      name: "Edebex",
      logo: edebex,
    },
    {
      id: "Karmen",
      name: "Karmen",
      logo: karmen,
    },
    {
      id: "KarmenFactor",
      name: "Karmen Factor",
      logo: karmen,
    },
    {
      id: "MemoBank",
      name: "MemoBank",
      logo: memobank,
    },
    {
      id: "Ocean",
      name: "Ocean",
      logo: ocean,
    },
    {
      id: "Riverbank",
      name: "Riverbank",
      logo: riverbank,
    },
    {
      id: "RiverbankInstant",
      name: "Riverbank Instant",
      logo: riverbank,
    },
    {
      id: "Silvr",
      name: "Silvr",
      logo: silvr,
    },
    {
      id: "YouTrade",
      name: "YouTrade",
      logo: youtrade,
    },
  ];

  const partners = partnersData
    .map((partner) => partner.id)
    .map((partnerID) => ({
      id: partnerID,
      name: partnersData.filter((partner) => partner.id === partnerID)[0].name,
      summary: t([partnerTransKey(partnerID), "summary"].join(".")),
      logo: (
        <img
          alt={`logo-${partnerID}`}
          src={
            partnersData.filter((partner) => partner.id === partnerID)[0].logo
          }
        />
      ),
      sections: ["about", "conditions", "costs"].map((section) =>
        getSection(partnerID, section),
      ),
    }));

  const handleCloseDrawer = () => {
    setSelectedPartner("");
    onClose();
  };

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={handleCloseDrawer}
      className="PartnersDrawer"
    >
      <div className="PartnersDrawer-header">
        <div className="PartnersDrawer-header-title">
          <span className="PartnersDrawer-header-title-text">
            <div className="PartnersDrawer-header-title-text-logo">
              <img src={logo} />
            </div>
            {t("financing:assessment.drawer.title")}
          </span>
          <Button
            className="PartnersDrawer-header-title-button"
            icon={<Close />}
            onClick={handleCloseDrawer}
          />
        </div>
        <div className="PartnersDrawer-header-subtitle">
          {t("financing:assessment.drawer.message")}
        </div>
      </div>

      <div className="PartnersDrawer-content">
        <div className="PartnersDrawer-content-partners">
          {result &&
            result
              .sort((resultA, resultB) => (resultA[0] < resultB[0] ? -1 : 1)) // sort partner ids alphabetically
              .map(([partnerID, eligible]) => (
                <Partner
                  key={partnerID}
                  eligible={eligible}
                  selected={selectedPartner === partnerID}
                  onSelect={handleSelectPartner}
                  {...partners.filter(
                    (partner) =>
                      partner.id.toLowerCase() === partnerID.toLowerCase(),
                  )[0]}
                />
              ))}
        </div>
        {eligible && onNext && result && (
          <Button
            className="PartnersDrawer-content-CTA"
            variant={"primary_a"}
            text={t("financing:assessment.drawer.button")}
            icon={<ArrowForward />}
            onClick={onNext}
          />
        )}
      </div>
    </Drawer>
  );
}

function Result({
  id = "stock",
  name = "Dépenses fournisseurs (stock)",
  description,
  img,
  onSelect,
  eligible = false,
}) {
  const { t } = useTranslation(["common"]);

  return (
    <Card
      className="Result"
      title={name}
      onClick={() => onSelect(id, eligible)}
    >
      <ImageContainer className="Result-img" src={img} alt={name} />
      <CardText heading={name} subheading={description} />
      <div style={{ position: "absolute", top: "1rem", left: "1rem" }}>
        <Pill
          text={
            eligible
              ? capitalize(t("common:available"))
              : capitalize(t("common:unavailable"))
          }
          color={eligible ? "green" : "red"}
        />
      </div>
    </Card>
  );
}

function Partner({
  selected,
  onSelect,
  eligible,
  summary,
  id,
  name,
  logo,
  sections,
}) {
  const { t } = useTranslation(["common", "financing"]);
  return (
    <div className="Partner">
      <div
        className="Partner-wrapper"
        onClick={() => onSelect(id)}
        style={{
          borderBottom: !selected ? "1px solid var(--color-neutral-2)" : "none",
        }}
      >
        <div className="Partner-wrapper-header">
          <span className="Partner-wrapper-header-name">{name}</span>
          <Pill
            text={
              eligible
                ? t("financing:assessment.partners.common.eligible")
                : t("financing:assessment.partners.common.nonEligible")
            }
            color={eligible ? "green" : "red"}
            selected={selected}
            useSelection
          />
        </div>
        <p
          className="Partner-wrapper-summary"
          style={{ whiteSpace: selected ? "unset" : "nowrap" }}
        >
          {summary}
        </p>
      </div>

      <PartnerDetails
        key={id}
        eligible={eligible}
        selected={selected}
        id={id}
        logo={logo}
        sections={sections}
      />
    </div>
  );
}

export function Pill({
  className,
  text,
  color,
  selected,
  useSelection = false,
}) {
  const variantEnum = { green: "green", red: "red", yellow: "yellow" };
  const [variantID] = useState(variantEnum[color] || "default");
  const variants = [
    {
      id: "default",
      color: "var(--color-leano-2)",
      backgroundColor: "var(--color-leano-6)",
    },
    {
      id: "green",
      color: "var(--color-success-1)",
      backgroundColor: "var(--color-success-2)",
    },
    {
      id: "red",
      color: "var(--color-error-1)",
      backgroundColor: "var(--color-error-2)",
    },
    {
      id: "yellow",
      color: "var(--color-pending-1)",
      backgroundColor: "var(--color-pending-2)",
    },
  ];
  return (
    <span
      className={["Pill", className].filter(Boolean).join(" ")}
      style={{
        borderColor: variants.filter((variant) => variant.id === variantID)[0]
          .color,
        color: variants.filter((variant) => variant.id === variantID)[0].color,
        backgroundColor: variants.filter(
          (variant) => variant.id === variantID,
        )[0].backgroundColor,
        cursor: useSelection ? "pointer" : "unset",
      }}
    >
      {text}
      {useSelection ? (
        !selected ? (
          <ChevronRight style={{ fontSize: "small" }} />
        ) : (
          <ExpandMore style={{ fontSize: "small" }} />
        )
      ) : null}
    </span>
  );
}

function PartnerDetails({ selected, logo, sections }) {
  return (
    <div
      className="Partner-details"
      style={{
        display: selected ? "block" : "none",
        borderBottom: selected ? "1px solid var(--color-neutral-2)" : "none",
      }}
    >
      <div style={{ width: "10rem" }}>{logo}</div>
      <div style={{ margin: "1rem 0" }}>
        {sections &&
          sections.map((section) => (
            <PartnerSection key={section.title} {...section} />
          ))}
      </div>
    </div>
  );
}

function PartnerSection({ title, items }) {
  return (
    <details>
      <summary style={{ margin: "0.5rem 0", cursor: "pointer" }}>
        {title}
      </summary>
      <ul>
        {Array.isArray(items) &&
          items.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
    </details>
  );
}

export function ProgressBar({ length = 5, index = 1, status = false }) {
  return (
    <div className="ProgressBar">
      <span className="ProgressBar-numerical">
        {`${status ? index : index + 1}/${length}`}
      </span>
      <div className="ProgressBar-wrapper">
        <div
          className="ProgressBar-wrapper-progress"
          style={{
            width: `${((status ? index : index + 1) * 100) / length}%`,
            backgroundColor:
              (status && index === length) || (!status && index + 1 === length)
                ? "var(--color-success-1)"
                : "var(--color-leano-5)",
          }}
        ></div>
      </div>
    </div>
  );
}

function Question({
  id,
  question,
  value,
  currency,
  min,
  type,
  options,
  onChange,
  button,
  onForward,
  onBackward,
}) {
  const { t } = useTranslation(["common", "financing"]);

  const inputs = [
    {
      type: type,
      name: id,
      label:
        type === "number" && value
          ? `${question} (${useFormatValue(
              value,
              i18n.resolvedLanguage,
              currency,
            )})`
          : question,
      value: value,
      min: min,
      onChange: onChange,
      required: true,
      options: options,
    },
  ];

  const form = {
    inputs: [{ data: inputs }],
    onSubmit: onForward,
    onCancel: onBackward,
    button: button,
    cancelButton: t("common:back"),
    resetLoader: true,
  };
  return <Form {...form} />;
}

function useResults(
  init = {
    long_term: {
      Defacto: true,
      Silvr: false,
    },
    short_term: {
      Defacto: true,
      Silvr: false,
      Karmen: true,
    },
  },
) {
  const [results, setResults] = useState(init);
  return { results, setResults };
}
