import { useState } from "react";
import api from "../../../api/resources";
import Form from "../../commons/Form";
import { Setting, SettingDisplay, SettingModder } from "../Settings";
import { useTranslation } from "react-i18next";
import Address from "../../commons/Address";
import { useModalDispatch } from "../../commons/Modal/ModalContext";

export default function AddressSet({ value, onSuccess }) {
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.address.label");

  const modal = {
    component: <AddressForm label={label} initValue={value} />,
    onSuccess: () => onSuccess(),
  };

  return (
    <Setting>
      <SettingDisplay label={label} value={<Address address={value} />} />
      <SettingModder modal={modal} />
    </Setting>
  );
}

function AddressForm({ label, initValue }) {
  const { t } = useTranslation(["common", "settings"]);
  const dispatchModal = useModalDispatch();

  const [errorsObj, setErrorsObj] = useState({});

  const [formAddress, setFormAddress] = useState({
    ...initValue,
    line_2: initValue.line_2 || "",
    line_3: initValue.line_3 || "",
  });

  const handleFormAddressChange = (value, addressField) => {
    setFormAddress((prevAddress) => ({
      ...prevAddress,
      [addressField]: value,
    }));
  };

  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  const handleSubmit = async () => {
    setErrorsObj({});
    const response = await api.updateActiveCompany(
      JSON.stringify({ address: formAddress }),
    );
    if (response.ok) {
      dispatchModal({ type: "success" });
    } else {
      const errors = await response.json();
      setErrorsObj(errors);
    }
  };

  const inputs = [
    {
      label: label,
      placeholder: t("settings:items.address.placeholders.street"),
      name: "street",
      value: formAddress.line_1,
      onChange: (value) => handleFormAddressChange(value, "line_1"),
      errors: getErrors("address"),
    },
    {
      placeholder: t("settings:items.address.placeholders.complement"),
      required: false,
      name: "complement",
      value: formAddress.line_2,
      onChange: (value) => handleFormAddressChange(value, "line_2"),
    },
    {
      placeholder: t("settings:items.address.placeholders.postalCode"),
      name: "postalCode",
      value: formAddress.code,
      onChange: (value) => handleFormAddressChange(value, "code"),
    },
    {
      placeholder: t("settings:items.address.placeholders.city"),
      name: "town",
      value: formAddress.town,
      onChange: (value) => handleFormAddressChange(value, "town"),
    },
  ];

  const form = {
    inputs: [{ data: inputs }],
    inputErrors: Object.keys(errorsObj) ? true : false,
    onSubmit: handleSubmit,
    errors: getErrors("non_field_errors"),
  };

  return <Form {...form} />;
}
