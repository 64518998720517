import { useState } from "react";
import authAPI from "../../../api/authentication";
import Form from "../../commons/Form";
import {
  Setting,
  SettingDisplay,
  SettingModder,
  VerifyPasswordModal,
} from "../Settings";
import { useTranslation } from "react-i18next";
import { useToastsDispatch } from "../../commons/Toasts/ToastsContext";
import { useModalDispatch } from "../../commons/Modal/ModalContext";

export default function EmailSet({ value, onSuccess }) {
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.email.label");
  const dispatchModal = useModalDispatch();

  const modal = {
    title: t("settings:modals.verifyPassword.title"),
    message: t("settings:modals.verifyPassword.message"),
    component: (
      <VerifyPasswordModal
        onNextModal={() =>
          dispatchModal({
            type: "add",
            title: t("common:ModifyTitle"),
            component: <EmailForm label={label} initialValue={value} />,
            onSuccess: onSuccess,
          })
        }
      />
    ),
  };

  return (
    <Setting>
      <SettingDisplay label={label} value={value} />
      <SettingModder modal={modal} />
    </Setting>
  );
}

function EmailForm({ label, initialValue }) {
  const [value, setValue] = useState(initialValue);
  const [errorsObj, setErrorsObj] = useState({});

  const dispatchToast = useToastsDispatch();
  const dispatchModal = useModalDispatch();
  const { t } = useTranslation(["common", "settings"]);

  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  const handleSubmit = async () => {
    setErrorsObj({});

    const data = new FormData();
    data.append("email", value);
    data.append("action_add", true);

    const response = await authAPI.changeEmail(data);
    if (response.ok) {
      dispatchToast({
        type: "add",
        variant: "info",
        heading: t("settings:toasts.confirmEmail.heading"),
        subheading: t("settings:toasts.confirmEmail.subheading"),
      });
      dispatchModal({ type: "success" });
    } else {
      const errors = await response.json();
      setErrorsObj(errors);
    }
  };

  const inputs = [
    {
      label: label,
      name: "email",
      value: value,
      onChange: setValue,
      errors: getErrors("email"),
    },
  ];

  const form = {
    inputs: [
      {
        data: inputs,
      },
    ],
    inputErrors: Object.keys(errorsObj) ? true : false,
    onSubmit: handleSubmit,
    errors: getErrors("non_field_errors"),
  };

  return <Form {...form} />;
}
