import { Setting, SettingDisplay, SettingModder } from "../Settings";
import { useTranslation } from "react-i18next";
import { UserX } from "react-feather";
import { useState } from "react";
import api from "../../../api/resources";
import { useAuth } from "../../../auth/AuthContext";
import Form from "../../commons/Form";
import { useNavigate } from "react-router-dom";
import routes from "../../../routes/routes";
import { useModalDispatch } from "../../commons/Modal/ModalContext";

export default function AccountDel() {
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.deleteAccount.label");
  const modal = useAccountDelModal();

  return (
    <Setting>
      <SettingDisplay label={label} />
      <SettingModder icon={<UserX />} action="delete" modal={modal} />
    </Setting>
  );
}

export function DeleteAccountModal() {
  const dispatchModal = useModalDispatch();

  const { t } = useTranslation(["common", "settings"]);
  const [reason, setReason] = useState(null);
  const [errorsObj, setErrorsObj] = useState({});

  const handleDeleteAccount = async () => {
    setErrorsObj({});

    const response = await api.deleteAccount(reason.value);

    if (response.ok) {
      setReason(null);
      dispatchModal({ type: "success" });
    } else {
      setErrorsObj({
        non_field_errors: [
          t("settings:modals.deleteAccount.component.form.select.error"),
        ],
      });
      // const errors = await response.json();
      // setErrorsObj(errors);
    }
  };

  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  const reasonInput = [
    {
      label: t("settings:modals.deleteAccount.component.form.select.label"),
      name: "reason",
      type: "select",
      value: reason,
      onChange: setReason,
      errors: getErrors("reason"),
      options: [
        {
          label: t(
            "settings:modals.deleteAccount.component.form.select.options.used.label",
          ),
          value: "used",
        },
        {
          label: t(
            "settings:modals.deleteAccount.component.form.select.options.complicated.label",
          ),
          value: "complicated",
        },
        {
          label: t(
            "settings:modals.deleteAccount.component.form.select.options.useless.label",
          ),
          value: "useless",
        },
        {
          label: t(
            "settings:modals.deleteAccount.component.form.select.options.expensive.label",
          ),
          value: "expensive",
        },
        {
          label: t(
            "settings:modals.deleteAccount.component.form.select.options.other.label",
          ),
          value: "other",
        },
      ],
    },
  ];

  return (
    <Form
      inputErrors={Object.keys(errorsObj) ? true : false}
      onSubmit={handleDeleteAccount}
      inputs={[{ data: reasonInput }]}
      errors={getErrors("non_field_errors")}
    />
  );
}

export function useAccountDelModal() {
  const { t } = useTranslation(["common", "settings"]);
  const { onLogout } = useAuth();
  const navigate = useNavigate();

  const handleSuccess = async () => {
    const response = await onLogout();
    if (response.ok) navigate(routes.Home);
  };

  const modal = {
    title: t("settings:modals.deleteAccount.title"),
    message: t("settings:modals.deleteAccount.message"),
    component: <DeleteAccountModal />,
    overflow: "visible",
    onSuccess: () => handleSuccess(),
  };

  return modal;
}
