// import { useState } from "react";
// import api from "../../../api/resources";
// import Form from "../../commons/Form";
import { Setting, SettingDisplay, SettingLocker } from "../Settings";
import { useTranslation } from "react-i18next";

export default function SurnameSet({ value }) {
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.surname.label");

  return (
    <Setting>
      <SettingDisplay label={label} value={value} />
      <SettingLocker />
    </Setting>
  );
}

// function SurnameForm({ label, value, onCancel, onChange }) {
//   const [errorsObj, setErrorsObj] = useState({});

//   const getErrors = (property) =>
//     errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

//   const handleSubmit = async () => {
//     setErrorsObj({});
//     const response = await api.updateUser(JSON.stringify({ surname: value }));
//     if (response.ok) {
//       onCancel();
//     } else {
//       const errors = await response.json();
//       setErrorsObj(errors);
//     }
//   };

//   const inputs = [
//     {
//       label: label,
//       surname: "surname",
//       value: value,
//       onChange: (value) => onChange(value),
//       errors: getErrors("surname"),
//     },
//   ];

//   const form = {
//     inputs: [
//       {
//         data: inputs,
//       },
//     ],
//     inputErrors: Object.keys(errorsObj) ? true : false,
//     onSubmit: handleSubmit,
//     onCancel: onCancel,
//     errors: getErrors("non_field_errors"),
//   };

//   return <Form {...form} />;
// }
