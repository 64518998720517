import { useState } from "react";
import api from "../../../api/resources";
import Form from "../../commons/Form";
import { Setting, SettingDisplay, SettingModder } from "../Settings";
import { useTranslation } from "react-i18next";
import { useModalDispatch } from "../../commons/Modal/ModalContext";

export default function CompanyNameSet({ value, onSuccess }) {
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.company.name.label");
  const modal = {
    component: <CompanyNameForm label={label} initValue={value} />,
    onSuccess: () => onSuccess(),
  };

  return (
    <Setting>
      <SettingDisplay label={label} value={value} />
      <SettingModder modal={modal} />
    </Setting>
  );
}

function CompanyNameForm({ label, initValue }) {
  const [value, setValue] = useState(initValue);

  const dispatchModal = useModalDispatch();
  const [errorsObj, setErrorsObj] = useState({});

  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  const handleSubmit = async () => {
    setErrorsObj({});
    const response = await api.updateActiveCompany(
      JSON.stringify({ name: value }),
    );
    if (response.ok) {
      dispatchModal({ type: "success" });
    } else {
      const errors = await response.json();
      setErrorsObj(errors);
    }
  };

  const inputs = [
    {
      label: label,
      name: "name",
      value: value,
      onChange: setValue,
      errors: getErrors("name"),
    },
  ];

  const form = {
    inputs: [
      {
        data: inputs,
      },
    ],
    inputErrors: Object.keys(errorsObj) ? true : false,
    onSubmit: handleSubmit,
    errors: getErrors("non_field_errors"),
  };

  return <Form {...form} />;
}
