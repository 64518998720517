import { useState } from "react";
import { Eye, EyeOff } from "react-feather";
import PhoneInput from "react-phone-number-input";
import CustomSelect from "./CustomSelect";
import FileDrop from "./FileDrop";
import { Radio } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

// Input-component Container (label + input + errors container)
export default function Input({
  label,
  type,
  name,
  autoComplete,
  value = "",
  minLength,
  maxLength,
  min,
  max,
  step,
  onChange,
  errors = [],
  className,
  disabled,
  placeholder = "",
  required = true,
  options,
  isSearchable,
  pattern,
  noOptionsMessage,
  isMulti,
  checkedIcon,
}) {
  const assignClassNames = () => {
    let classNames = ["Input"];
    if (className) classNames = [...classNames, className];
    if (type === "checkbox") classNames = [...classNames, "CheckboxInput"];
    return classNames.join(" ");
  };

  return (
    <div className={assignClassNames()}>
      <label>
        {label ? <span>{label}</span> : null}
        <InputType
          type={type}
          name={name}
          id={name}
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          options={options}
          isSearchable={isSearchable}
          maxLength={maxLength}
          minLength={minLength}
          min={min}
          max={max}
          step={step}
          pattern={pattern}
          noOptionsMessage={noOptionsMessage}
          isMulti={isMulti}
          checkedIcon={checkedIcon}
        />
      </label>

      {errors.length > 0 ? (
        <ul className="Input-errors">
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
}

// Helper component to choose type
function InputType({
  type,
  name,
  autoComplete,
  value,
  onChange,
  disabled,
  placeholder,
  required,
  options,
  isSearchable,
  maxLength,
  minLength,
  min,
  max,
  step,
  pattern,
  noOptionsMessage,
  isMulti,
  checkedIcon,
}) {
  const handleValueChange = ({ target: { value } }) => onChange(value);
  const handleCheck = ({ target: { checked } }) => onChange(checked);

  switch (type) {
    case "password":
      return (
        <PasswordInput
          autoComplete={autoComplete}
          value={value}
          name={name}
          minLength={8}
          maxLength={50}
          onChange={handleValueChange}
          required={required}
        />
      );
    case "tel":
      return (
        <PhoneInput
          value={value}
          onChange={onChange}
          international
          name="phone"
          required={required}
        />
      );
    case "checkbox":
      return (
        <input
          required={required}
          type="checkbox"
          checked={value}
          disabled={disabled}
          onChange={handleCheck}
          name={name}
        />
      );
    case "select":
      return (
        <CustomSelect
          value={value}
          options={options}
          onSelect={onChange}
          placeholder={placeholder}
          required={required}
          isSearchable={isSearchable}
          disabled={disabled}
          noOptionsMessage={noOptionsMessage}
          isMulti={isMulti}
        />
      );
    case "file":
      return <FileDrop value={value} onChange={onChange} />;
    case "radio":
      return (
        <RadioGroup
          name={name}
          required={required}
          value={value}
          options={options}
          onChange={onChange}
          checkedIcon={checkedIcon}
        />
      );
    default:
      return (
        <input
          required={required}
          type={type}
          name={name}
          id={name}
          autoComplete={autoComplete}
          value={value}
          onChange={handleValueChange}
          disabled={disabled}
          placeholder={placeholder}
          minLength={minLength}
          maxLength={maxLength}
          min={min}
          max={max}
          step={step}
          pattern={pattern}
        />
      );
  }
}

// Special password component with password toggle logic
function PasswordInput({ autoComplete, value, onChange, name, required }) {
  const [shownPassword, setShownPassword] = useState(false);
  const togglePassword = () => setShownPassword(!shownPassword);

  return (
    <div className="PasswordInput">
      <input
        className="PasswordInputInput"
        name={name}
        id={name}
        required={required}
        type={shownPassword ? "text" : "password"}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
      />
      <div onClick={togglePassword} className="PasswordInputToggle">
        {shownPassword ? <EyeOff /> : <Eye />}
      </div>
    </div>
  );
}

function RadioGroup({
  name,
  required,
  options,
  value,
  onChange,
  checkedIcon = <CheckCircle sx={{ color: "var(--color-success-1)" }} />,
}) {
  const handleChange = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <div className="RadioGroup">
      {options.map((option, index) => (
        <div key={index} className="RadioGroup-Input">
          {option.label && (
            <label>
              <Radio
                sx={{ padding: 0 }}
                name={name}
                required={required}
                checkedIcon={checkedIcon}
                value={option.value}
                onChange={handleChange}
                checked={
                  value && typeof value !== "boolean"
                    ? value.toString() === option.value.toString()
                    : false
                }
              />
              {option.label}
            </label>
          )}
        </div>
      ))}
    </div>
  );
}
